
import * as React from "react";
import { ApplicationLink } from "./quick-navigation-panel";
import { Row } from "../shared/layout";
import { Application as ApplicationName, Application } from "./application";
import { Icon } from "antd";
import { Forecast } from "../../model/forecast";

import excelIcon from "../../content/excel-icon.png";

interface QuickAccessLinkProps {
    app: ApplicationLink;
    forecast: Forecast;
}

export class QuickAccessLink extends React.Component<QuickAccessLinkProps> {

    public render(): JSX.Element {

        const { app, forecast } = this.props;

        return <Row
            style={{ cursor: "pointer", fontSize: "calc(15px + 1vmin)", width: "55%", lineHeight: "calc(15px+1vmin)" }}
            onClick={this.navigateToApp}
            title={`Navigate to ${getAppName(app.name, forecast)} with preloaded ${forecast.path}`}
        >
            {
                getIconForApplication(app.name, { padding: "0.2vh 0" })
            }
            <div
                className="ant-btn ant-btn-link"
                style={{ textOverflow: "elipsis", fontSize: "calc(6px + 1vmin)" }}
            >
                {forecast.name}
                <Icon style={{ padding: "0.2vh 0.2vw", margin: "0 0.2vw" }} type="double-right" />
            </div>
        </Row >
    }

    private navigateToApp = () => {
        const { app, forecast } = this.props;

        const urlToNavigateTo = `${app.url}?forecastId=${forecast.id}@${forecast.latestVersion}`;
        window.open(urlToNavigateTo, app.name);
    }
}

export function getAppName(appName: Application, forecast: Forecast) {

    if (appName !== Application.Skymod) {
        return appName;
    } else {

        return forecast && forecast.economicDomain && forecast.economicDomain.code !== "Unknown"
            ? forecast.economicDomain.name
            : Application.Skymod
    }

}

export function getIconForApplication(app: ApplicationName, iconStyles?: React.CSSProperties): JSX.Element {

    switch (app) {

        case ApplicationName.Skymod:
            return <Icon type="global" style={{ ...iconStyles }} />

        case ApplicationName.Dashboards:
            return <Icon type="line-chart" style={{ ...iconStyles }} />

        case ApplicationName.Trellis:
            return <Icon type="table" style={{ ...iconStyles }} />

        case ApplicationName.Scenarios:
            return <Icon type="branches" style={{ ...iconStyles }} />

        case ApplicationName.Website:
            return <Icon type="settings" style={{ ...iconStyles }} />

        case ApplicationName.ExcelPlugin:
            return <img src={excelIcon} style={{ width: "1vw", ...iconStyles }} alt="Excel icon" />

        case ApplicationName.ApiDocs:
            return <Icon type="api" style={{ ...iconStyles }} />;

        case ApplicationName.GlobalScenarioTool:
            return <Icon type="global" style={{ ...iconStyles }} />

        default:
            const applicationResult: never = app;

            console.warn(`Unable to find icon for ${applicationResult}`);
            return <Icon type="settings" style={{ ...iconStyles }} />
    }
}