
import { EconomicDomain as FileBrowserEconomicDomain } from "@oxfordeconomics/file-browser";

export class EconomicDomain {

    constructor(
        public readonly code: string,
        public readonly name: string,
        public readonly iconUrl: string,
    ) {
    }

    public static fromFileBrowserDomain(ed: FileBrowserEconomicDomain): EconomicDomain {

        if (ed.IconUrl) {
            return new EconomicDomain(ed.Code, ed.Name, ed.IconUrl);
        }
        else {
            return new EconomicDomain(ed.Code, ed.Name, "");
        }
    }
}