import React from "react";
import { Row } from "../shared/layout";

interface UserProps {
    name: string;
    company: string;
}

export class User extends React.Component<UserProps> {

    public render(): JSX.Element {

        return <Row style={{ color: "#fff", fontSize: "calc(4px + 1vmin)", alignItems: "center" }}>
            <div style={{ margin: "0px 5px" }}>{this.props.name}</div>
            <div><i>{this.props.company}</i></div>
        </Row>
    }

}