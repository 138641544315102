import * as React from "react";
import { Footer } from "./layout";

export function GmwoFooter() {
    return <Footer className="gmwo-footer">
        {[
            ["Website", "https://www.oxfordeconomics.com"],
            ["Support Portal", "https://support.oxfordeconomics.com"],
            ["Terms and Conditions", "https://www.oxfordeconomics.com/terms-and-conditions"]
        ]
            .map(([text, url]) => <a href={url} target="_blank" rel="noopener noreferrer">{text}</a>)
        }
        © Oxford Economics 2022 all rights reserved
    </Footer>
}