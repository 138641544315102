import * as React from 'react';
import { Header } from './layout';
import thinLogo from "../../content/thin-logo.png";

export function GmwoHeader() {
    return <Header style={{ background: "#fff", display: "flex", padding: "1vh 5vw", margin: 0, height: "8vh", alignItems: "center" }} className="App-header">

        <img style={{ height: "4vh" }} src={thinLogo} className="App-logo" alt="logo" />
        <div>
            <h1 style={{ fontSize: "2.5vh", marginTop: "1.5vh", padding: "1vh" }}>Global Model Workstation Online </h1>
        </div>

    </Header>
}