import * as React from "react";
import { Application } from "./application";
import { QuickAccessLink } from "./quick-access-link";
import { AppNav } from "../../services/history-service";
import { gridContainerStyle, SeparatorColor } from "./styles";
import { CardHeader } from "./card-header";

export interface ApplicationLink {
    name: Application;
    url: string;
}

interface QuickNavProps {
    skymodUrl: string;
    history: AppNav[];
}

export const QuickNavPanel: React.FunctionComponent<QuickNavProps> =
    React.memo(
        (props: QuickNavProps) => {

            return <div style={{ ...gridContainerStyle, overflow: "hidden", userSelect: "none" }}>

                <CardHeader headerText={"Recent Activities"} showUserGuide={false} />
                <div style={{ textAlign: "left", borderTop: `1px solid ${SeparatorColor}` }}>
                    <div style={{ margin: "2vh 0" }}>

                        {
                            renderApplicationLinks(props.history)
                        }

                    </div>
                </div>

            </div>
        },
        () => false
    );

function renderApplicationLinks(apps: AppNav[]): JSX.Element {

    return <div>

        {
            apps.length === 0 && <h4>No recent activity found ...</h4>
        }

        {
            apps.map((app, index) => <QuickAccessLink key={`${app.app.name}-${app.forecast.id}-${index}`} {...app} />)
        }

    </div>
}
