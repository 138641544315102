export enum Application {
    Skymod = "Global Economic Model",
    Trellis = "Generate results tables",
    ExcelPlugin = "View and download data",
    Scenarios = "Predefined scenarios",
    Dashboards = "Dashboards",
    Website = "Website",
    ApiDocs = "API",
    GlobalScenarioTool = "Global Scenario Tool"
}
