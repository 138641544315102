import * as React from "react";

import { CardHeader } from "../Gmw-like-homepage/card-header";
import { gridContainerStyle } from "../Gmw-like-homepage/styles";
import { GmwoHeader } from "./gmwo-header";
import { Layout } from "./layout";

type ErrorPageProps = {
    title: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ title, children }) => {
    return <Layout className="App" style={{ height: "auto", textAlign: "center" }} >
        <GmwoHeader />
        <div style={{ padding: "1vh 5vw" }}>
            <div style={{ ...gridContainerStyle, width: "inherit", display: "inline-block" }}>
                <CardHeader headerText={title} showUserGuide={false} />
                {children}
                <p>Need help? Please visit our <a href="https://support.oxfordeconomics.com">Support Centre</a>.</p>
            </div>
        </div>
    </Layout>
}