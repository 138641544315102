import React, { CSSProperties } from 'react';

import { Application } from './application';
import { getAppName } from './quick-access-link';
import { gridContainerStyle } from "./styles";
import { Cards } from './cards';
import { CardHeader } from "./card-header";
import { AppCard } from "../shared/app-card";
import { ApplicationLink } from './quick-navigation-panel';

import skymodLogoImage from "../../content/gmw-standard.png";
import scenariosLogoImage from "../../content/scenarios-resized.png";
import { FeaturedForecast } from '../../model/featured-forecast';

const GMWBulletPoints = [
    "Compare results across scenarios",
    "Undertake multiple scenarios simultaneously",
    "Collaborate online with colleagues",
]

export function renderRunModelSection(
    navBuilder: (applicationLink: ApplicationLink) => (navigate: boolean, forecastIdUrlPart?: string) => (e: React.MouseEvent) => void,
    skymodUi: string,
    scenariosUrl: string,
    detailedMode: boolean,
    selectedForecast: FeaturedForecast | undefined,
    forecastIdUrlPart: string,
    imageStyle: CSSProperties
): JSX.Element {

    const scenariosForecastUrlPart: string = selectedForecast
        ? `&forecastId=${selectedForecast.id}@${selectedForecast.latestVersion}`
        : "";

    const gstUrlPart: string = selectedForecast && selectedForecast.Features.includes("GlobalScenarioTool")
        ? `&openKeyAssumptionsAt=Global Scenario Tool`
        : "";

    const skymodNavHandler: (navigate: boolean, forecastIdUrlPart?: string) => (e: React.MouseEvent) => void =
        navBuilder({ name: Application.Skymod, url: skymodUi });
    const scenarioNavHandler: (navigate: boolean, forecastIdUrlPart?: string) => (e: React.MouseEvent) => void =
        navBuilder({ name: Application.Scenarios, url: scenariosUrl });
    const globalScenarioToolNavHandler: (navigate: boolean, forecastIdUrlPart?: string) => (e: React.MouseEvent) => void =
        navBuilder({ name: Application.GlobalScenarioTool, url: skymodUi + forecastIdUrlPart + gstUrlPart});

    const skymodLogo: JSX.Element = <img style={imageStyle} alt="Global Economic Model logo"
        src={skymodLogoImage} onClick={skymodNavHandler(true, forecastIdUrlPart)} />;

    const scenariosLogo: JSX.Element = <img style={imageStyle} alt="Predefined scenarios"
        src={scenariosLogoImage} onClick={scenarioNavHandler(true, scenariosForecastUrlPart)} />;

    const showScenariosCard: boolean = !selectedForecast || selectedForecast?.economicDomain.code === "MACRO";

    const modelApplicationTitle: string = selectedForecast
        ? getAppName(Application.Skymod, selectedForecast)
        : Application.Skymod;

    return <div style={gridContainerStyle}>
        <CardHeader headerText={Cards.RunModel} showUserGuide={true} userGuidePage={"#global-model-interface"} />
        <AppCard
            url={`${skymodUi}${forecastIdUrlPart}`}
            title={modelApplicationTitle}
            appDescription="Solve an economic model using your own assumptions and quantify the impact on the global economy."
            appDetails={
                <ul>
                    {GMWBulletPoints.map(i => <li key={i}>{i}</li>)}
                </ul>
            }
            showDetails={detailedMode}
            image={skymodLogo}
            linkText={"Launch model"}
            onLinkClick={skymodNavHandler(false)}
            onTitleClick={skymodNavHandler(true, forecastIdUrlPart)}
        />
        {
            selectedForecast && selectedForecast.Features.includes("GlobalScenarioTool") && forecastIdUrlPart && <AppCard
                url={`${skymodUi}${forecastIdUrlPart}${gstUrlPart}`}
                title={Application.GlobalScenarioTool}
                appDescription="Solve the Global Economic Model with alternative equations to quantify the implications of major shocks to GDP."
                showDetails={detailedMode}
                linkText={"Launch Global Scenario Tool"}
                onLinkClick={globalScenarioToolNavHandler(false)}
                onTitleClick={globalScenarioToolNavHandler(true)}
            />
        }
        {
            showScenariosCard && <AppCard
                url={`${scenariosUrl}${scenariosForecastUrlPart}`}
                title={Application.Scenarios}
                appDescription="Choose an alternative scenario and quantify its impact on the global economy."
                showDetails={detailedMode}
                linkText={"Create scenario"}
                onLinkClick={scenarioNavHandler(false)}
                onTitleClick={scenarioNavHandler(true, scenariosForecastUrlPart)}
                image={scenariosLogo}
            />
        }
    </div>;
}