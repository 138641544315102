import { Forecast } from "./forecast";
import { ForecastFeature } from "./forecast-feature";

export class FeaturedForecast extends Forecast {
    constructor(
        forecast: Forecast,
        public readonly Features: ForecastFeature[]
    ) {
        super(
            forecast.name,
            forecast.id,
            forecast.path,
            forecast.latestVersion,
            forecast.economicDomain
        );
    }

}
