export type Result<TSuccess, TError = string> = {
    success: true;
    value: TSuccess;
} | {
    success: false;
    error: TError;
};

export class Results{

    static Pass<TSuccess, TError = string>(value: TSuccess): Result<TSuccess, TError> {
        return { success: true as const, value };
    }

    static Fail<TError>(error: TError) {
        return { success: false as const, error };
    }
}
