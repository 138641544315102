import { captureMessage, captureException, Severity } from "@sentry/browser";

/**
 * Log an error to Sentry
 *
 * @export
 * @param {Error} e The exception to log
 */
export function logError(e: Error, message: string): void {

    if (process.env.NODE_ENV === "test") {
        throw e;
    }

    captureException(e);
    captureMessage(
        `${message} - ${JSON.stringify(e)}`,
        Severity.Error
    );

}

/**
 * Log a warning message to Sentry
 *
 * @export
 * @param {string} message The message to log
 */
export function logWarning(message: string): void {

    console.warn(message);

    if (process.env.NODE_ENV === "test") {
        return;

    }
    captureMessage(
        message,
        Severity.Warning
    );
}

/**
 * Log an info message to Sentry
 *
 * @export
 * @param {string} message
 */
export function logInfo(message: string): void {
    captureMessage(message, Severity.Info);
}
