import React from 'react';

import { Row, Layout, Content, Col } from "../shared/layout";
import { Button, Icon, Input } from 'antd';

import "./gmw.css";

import { ResourceTypes, PromptForOpenFile, SkyModService } from '@oxfordeconomics/file-browser';
import { FileGroups } from '@oxfordeconomics/file-browser/package/lib/components/file-browser-container';

import { User } from "./user";

import { AppCard } from "../shared/app-card";

import trellisLogo from "../../content/trellis-standard.png";
import dashboardLogo from "../../content/dashboards-standard.png";
import excelLogo from "../../content/excel-plugin-resized.png";

import { QuickNavPanel, ApplicationLink } from "./quick-navigation-panel";

import { Forecast } from "../../model/forecast";
import { HistoryService, AppNav } from '../../services/history-service';
import { Application } from './application';
import { Cards } from './cards';
import { OEBlue, gridContainerStyle } from "./styles";

import { CardHeader } from "./card-header";

import { ForecastDomainIcon } from "./forecast-domain-icon";
import { renderRunModelSection } from "./render-model-section";
import { SkyModClient } from '@oxfordeconomics/skymod';
import { FeaturedForecast } from '../../model/featured-forecast';
import { ForecastFeaturesService } from '../../services/forecast-features-service';
import { Session } from '../../model/session';
import { AppsConfig } from '../../model/apps-config';
import { GmwoFooter } from '../shared/gmwo-footer';
import { GmwoHeader } from '../shared/gmwo-header';

const imageStyle: React.CSSProperties = { width: "100%", cursor: "pointer" };

interface LayoutProps {
    history: HistoryService
    applications: AppsConfig;
    session: Session;
}

interface LayoutState {
    detailedMode: boolean;
    editLayout: boolean;
    forecastBrowserVisible: boolean;
    selectedForecast: FeaturedForecast | undefined;
    history: AppNav[];
}

class GMWLikeComponent extends React.Component<LayoutProps, LayoutState> {

    private readonly numberOfRows: number = 22;

    constructor(props: LayoutProps) {
        super(props);

        this.state = {
            detailedMode: true,
            editLayout: false,
            forecastBrowserVisible: false,
            selectedForecast: undefined,
            history: props.history.getHistory()
        };
    }

    private buildGetForecast = (skymodApi: SkyModService, forecastFeaturesService: ForecastFeaturesService) =>
        async () => {

            const selectedFile = await PromptForOpenFile({
                FileService: skymodApi,
                FileGroups: [
                    FileGroups.Forecasts
                ],
            });

            if (selectedFile?.Type === ResourceTypes.Forecast) {
                const forecast = Forecast.fromFileBrowserForecast(selectedFile);
                const features = await forecastFeaturesService.GetSupportedFeatures(forecast);
                this.setState({ selectedForecast: new FeaturedForecast(forecast, features) });
            }
        };

    private linkClick = (app: ApplicationLink) => (navigate: boolean, forecastIdPart?: string) => (e: React.MouseEvent) => {

        if (this.state.selectedForecast) {
            this.props.history.addNav(app, this.state.selectedForecast);
            this.setState({ history: this.props.history.getHistory() });
        }
        e.stopPropagation()

        if (navigate) {
            const urlToNavigateTo = `${app.url}${forecastIdPart}`;

            window.open(urlToNavigateTo, app.name);
        }
    }

    private logout = () => this.props.session.logout(window.location.href);

    private navigateToExcel = (url: string) => {
        window.open(url, Application.ExcelPlugin);
    }

    public render(): JSX.Element {

        const { skymodUi, trellisUrl, dashboards, pluginUrl, skymodApi, scenariosUrl, apiDocsUrl} = this.props.applications;

        const trellisNavHandler = this.linkClick({ name: Application.Trellis, url: trellisUrl });
        const dashboardsNavHandler = this.linkClick({ name: Application.Dashboards, url: dashboards });

        const skymodService = new SkyModService(skymodApi, this.props.session.token);
        const forecastFeaturesService = new ForecastFeaturesService(new SkyModClient(skymodApi, () => this.props.session.token));
        const forecastIdUrlPart: string = this.state.selectedForecast
        ? `?forecastId=${this.state.selectedForecast.id}@${this.state.selectedForecast.latestVersion}`
        : "";

        const TrellisLogo = <img style={imageStyle} alt="Generate results tables " src={trellisLogo} onClick={trellisNavHandler(true, forecastIdUrlPart)} />;
        const DashboardsLogo = <img style={imageStyle} alt="Dashboards logo" src={dashboardLogo} onClick={dashboardsNavHandler(true, forecastIdUrlPart)} />;
        const ExcelPluginLogo = <img style={imageStyle} alt="Excel plugin logo" src={excelLogo} onClick={() => { this.navigateToExcel(pluginUrl) }} />;

        return (
            <Layout className="App" style={{ height: "100%", textAlign: "center" }} >

                <GmwoHeader />

                <Content>
                    <Row style={{ height: "6vh", background: OEBlue, alignItems: "center", padding: "0vh 5vw" }}>
                        <label style={{ color: "#fff", fontSize: "calc(10px + 1vmin)", width: "12%" }} htmlFor="working-database">Economic model</label>
                        <div style={{ width: "25%", margin: "0 1vw" }}>
                            <Input
                                style={{ fontSize: "calc(10px + 1vmin)" }}
                                placeholder="Please select working model ..."
                                value={this.state.selectedForecast?.name}
                                addonBefore={
                                    <ForecastDomainIcon forecast={this.state.selectedForecast} />
                                }
                                addonAfter={
                                    <Icon type="folder-open" theme="twoTone" twoToneColor={OEBlue} onClick={this.buildGetForecast(skymodService, forecastFeaturesService)} />
                                }
                            />
                        </div>
                        <div style={{ display: "flex", width: "65%", justifyContent: "flex-end" }}>

                            <User name={this.props.session.name} company={this.props.session.company} />

                            <Button
                                type="primary"
                                style={{ margin: "0 1vw" }}
                                onClick={() => PromptForOpenFile({ FileService: skymodService, FileGroups: [FileGroups.All] })}
                                title="Browse files"
                            >
                                <div style={{ fontSize: "calc(6px + 1vmin)", lineHeight: "calc(6px + 1vmin)" }}>
                                    Browse files
                                </div>
                            </Button>

                            <Button
                                type="primary"
                                style={{ margin: "0 1vw" }}
                                onClick={this.logout}
                                title="Logout"
                            >
                                <div style={{ fontSize: "calc(6px + 1vmin)", lineHeight: "calc(6px + 1vmin)" }}>
                                    Logout
                                </div>
                            </Button>
                        </div>
                    </Row>
                    <Row style={{ padding: "10px 30px" }}>
                        <Col style={{ flexBasis: "40%", maxWidth: "900px" }}>
                            <div style={{ margin: "10px", height: "100%" }} key={Cards.RunModel}>

                                {
                                    renderRunModelSection(
                                        (link: ApplicationLink) => this.linkClick(link),
                                        skymodUi,
                                        scenariosUrl,
                                        this.state.detailedMode,
                                        this.state.selectedForecast,
                                        forecastIdUrlPart,
                                        imageStyle
                                    )
                                }
                            </div>
                        </Col>
                        <Col style={{ flexBasis: "40%", maxWidth: "900px" }}>
                            <div style={{ margin: "10px", height: "100%" }} key={Cards.VisualiseData}>
                                <div style={gridContainerStyle}>
                                    <CardHeader headerText={Cards.VisualiseData} showUserGuide={true} userGuidePage={"#view-and-download-data"} />

                                    <AppCard
                                        url={`${trellisUrl}${forecastIdUrlPart}`}
                                        title={Application.Trellis}
                                        appDescription="Quickly view forecasts and scenario data"
                                        showDetails={this.state.detailedMode}
                                        linkText={"Generate tables"}
                                        image={TrellisLogo}
                                        onLinkClick={trellisNavHandler(false)}
                                        onTitleClick={trellisNavHandler(true, forecastIdUrlPart)}
                                    />
                                    <AppCard
                                        url={`${dashboards}${forecastIdUrlPart}`}
                                        title={Application.Dashboards}
                                        appDescription="View graphical dashboards for a forecast or scenario"
                                        showDetails={this.state.detailedMode}
                                        linkText={"View dashboards"}
                                        image={DashboardsLogo}
                                        onLinkClick={dashboardsNavHandler(false)}
                                        onTitleClick={dashboardsNavHandler(true, forecastIdUrlPart)}
                                    />
                                    <AppCard
                                        title={Application.ExcelPlugin}
                                        
                                        showDetails={this.state.detailedMode}
                                        image={ExcelPluginLogo}
                                        items={[
                                            {
                                                url: pluginUrl,
                                                linkText: "Install Excel add-in",
                                                appDescription: "Use the Excel add-in to download detailed forecast and scenario data"
                                            },
                                            {
                                                url: apiDocsUrl,
                                                linkText: "Access API documentation",
                                                appDescription: <span style={{fontWeight: "bold", fontSize: "20px"}}>API</span>
                                            }
                                        ]}
                                        
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col style={{ flexBasis: "20%", maxWidth: "450px" }}>
                            <div style={{ margin: "10px" }} key="quick-nav">
                                <QuickNavPanel
                                    history={this.state.history}
                                    skymodUrl={skymodApi}
                                />
                            </div>
                        </Col>
                    </Row>
                </Content>
                <GmwoFooter />
            </Layout >

        );
    }
}

export default GMWLikeComponent;
