import { KeyAssumptionParseService } from "@oxfordeconomics/key-assumptions/key-assumption-parse-service";
import { KeyAssumptionPrerequisite, KeyAssumptionsBranch, KeyAssumptionsNode } from "@oxfordeconomics/key-assumptions/key-assumptions";
import { HttpError, SkyModClient } from "@oxfordeconomics/skymod";
import { Forecast } from "../model/forecast";
import { ForecastFeature } from "../model/forecast-feature";
import { memoizeAsync } from "../utilities/memoize";

declare global {
    interface Blob {
        text(): Promise<string>;
    }
}

export class ForecastFeaturesService {

    constructor(private readonly skymodClient: SkyModClient) {

    }

    public async GetSupportedFeatures(forecast: Forecast): Promise<Array<ForecastFeature>> {
        try {
            if (await this.SupportsRunModelKeyAssumption(forecast, ["Global Scenario Tool"])) {
                return ["GlobalScenarioTool"];
            } else {
                return [];
            }
        }
        catch (error) {
            console.error(`Failed to check supported features for forecast ${forecast.path} (${forecast.id}). ${error}`);
            return [];
        }
    }

    private async SupportsRunModelKeyAssumption(forecast: Forecast, keyAssumptionPath: string[]) {
        const keyAssumptions = await this.GetKeyAssumptions(forecast.economicDomain.code);
        const matchingKeyAssumptionsNode = FindKeyAssumptionsNode(keyAssumptions, keyAssumptionPath);

        return matchingKeyAssumptionsNode
            && (
                !matchingKeyAssumptionsNode.Prerequisite
                || await this.IsKeyAssumptionPrerequisiteMet(forecast, matchingKeyAssumptionsNode.Prerequisite)
            );
    }

    private async IsKeyAssumptionPrerequisiteMet(forecast: Forecast, prerequisite: KeyAssumptionPrerequisite) {
        switch (prerequisite.Type) {
            case "VariableExists":
                const variable = await this.skymodClient.forecastContents_GetVariablesFromLatest(
                    forecast.id,
                    [{ GroupCode: prerequisite.LocationCode, IndicatorCode: prerequisite.IndicatorCode }],
                    "GroupCode,IndicatorCode"
                );
                return !!variable;

            default:
                console.warn(`Unsupported prerequisite type: ${prerequisite.Type}.`);
                return false;
        }
    }

    @memoizeAsync({ maxAgeMs: 15 * 60 * 1000 }) // Cache results for 15 minutes
    private async GetKeyAssumptions(domainCode: string) {
        try {
            const keyAssumptionsResource = await this.skymodClient.resource_Get(`$global/config/domains/${domainCode}/run-model/key-assumptions`, true);
            const fileResponse = await this.skymodClient.file_DownloadLatest(keyAssumptionsResource.Id);
            const json = await fileResponse.data.text();
            const keyAssumptionsRaw = JSON.parse(json);
            const parseService = new KeyAssumptionParseService();
            return parseService.ParseRawKeyAssumptions(keyAssumptionsRaw);
        }
        catch (error) {
            // log if not 404
            if (!(error instanceof HttpError) || error.StatusCode !== 404) {
                console.error(`Failed to retrieve key assumptions: ${error}`);
            }

            return [];
        }
    }
}


function IsBranch(node: KeyAssumptionsNode): node is KeyAssumptionsBranch {
    const childrenPropName: keyof KeyAssumptionsBranch = "Children";
    return Object.keys(node).includes(childrenPropName);
}

function FindKeyAssumptionsNode(nodes: KeyAssumptionsNode[], path: string[], caseSensitive = false): KeyAssumptionsNode | undefined {
    if (!path.length) {
        throw new Error(`Cannot search key assumptions for empty path.`);
    }

    const matchingNode = nodes.find(node => caseSensitive ? node.Name === path[0] : node.Name.toLowerCase() === path[0].toLowerCase());
    if (matchingNode) {
        if (path.length === 1) {
            return matchingNode;
        } else if (IsBranch(matchingNode)) {
            return FindKeyAssumptionsNode(matchingNode.Children, path.slice(1), caseSensitive);
        } else {
            return undefined; // Leaf found where path expects a branch
        }
    } else {
        return undefined;
    }
}
