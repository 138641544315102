import * as React from "react";

import { Layout } from 'antd';
const { Header, Footer, Sider, Content } = Layout;


export {
    Layout,
    Header,
    Footer,
    Sider,
    Content
};

export class Row extends React.Component<React.HTMLAttributes<HTMLDivElement>>{

    public render() {
        return <div {...this.props} style={{ ...this.props.style, display: "flex", flexDirection: "row" }}>
            {this.props.children}
        </div>
    }
}

export class Col extends React.Component<React.HtmlHTMLAttributes<HTMLDivElement>>{
    public render() {
        return <div {...this.props} style={{ ...this.props.style, display: "flex", flexDirection: "column" }}>
            {this.props.children}
        </div>
    }
}
