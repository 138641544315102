import { Button, Input, Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import * as React from 'react';
import { Auth0Config } from '../../model/auth0-config';
import { fetchSafe } from '../../utilities/fetch';
import { ErrorPage } from './error-page';

export type PasswordExpiredPageProps = {
    auth0: Auth0Config;
    returnUrl?: string;
}

type AlertState = Pick<AlertProps, 'message' | 'type'>;

export const PasswordExpiredPage: React.FC<PasswordExpiredPageProps> = ({ auth0 }) => {

    const [email, setEmail] = React.useState<string>();
    const [alert, setAlert] = React.useState<AlertState>();

    const onPasswordResetClick = async (submittedEmail: string) => {
        const result = await sendChangePasswordRequest(auth0, submittedEmail);
        if (result.success) {
            setAlert({
                type: 'success',
                message: 'We\'ve just sent you an email to reset your password.'
            });
        } else {
            console.error(`Error occurred while requesting password reset email.`, result.error);
            setAlert({
                type: 'error',
                message: <>Sorry, there was a problem requesting a password reset.</>
            });
        }
    };

    const clearError = () => setAlert(undefined);

    return <ErrorPage title="Your password has expired">
        <p>Please provide your email address to request a new password.</p>
        <p><Input type="email" value={email || ''} onChange={e => setEmail(e.target.value)} placeholder='Your email address' /></p>
        <p><Button type='primary' disabled={!email} onClick={() => email && onPasswordResetClick(email)}>Reset password</Button></p>
        {!!alert && <Alert {...alert} closable onClose={clearError} />}
    </ErrorPage>
}

async function sendChangePasswordRequest(auth0Config: Auth0Config, email: string) {
    const schemeAndHost = auth0Config.domain.match(/^https?:/)
        ? auth0Config.domain
        : `https://${auth0Config.domain}`
        ;

    const request = new Request(
        `${schemeAndHost}/dbconnections/change_password`,
        {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                client_id: auth0Config.clientId,
                email,
                connection: auth0Config.databaseConnectionName
            })
        }
    );

    return fetchSafe(request);
}