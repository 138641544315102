import { Forecast as FileBrowserForecast } from "@oxfordeconomics/file-browser";

import { EconomicDomain } from "./economic-domain";

export class Forecast {

    constructor(
        public readonly name: string,
        public readonly id: string,
        public readonly path: string,
        public readonly latestVersion: number,
        public readonly economicDomain: EconomicDomain

    ) { }

    public static fromFileBrowserForecast(forecast: FileBrowserForecast): Forecast {

        return new Forecast(
            forecast.Versions[forecast.Versions.length - 1].Name,
            forecast.Id,
            forecast.Path.Segments.join('/'),
            forecast.Versions.length - 1,
            EconomicDomain.fromFileBrowserDomain(forecast.Domain)
        );
    }
}