import React from "react";
import { mainHeaderStyle } from "./styles";
import { Button, Icon } from "antd";

import { Row } from "../shared/layout";

interface CardHeaderProps {
    headerText: string;
    showUserGuide: boolean;
    userGuidePage?: string;
}

export class CardHeader extends React.Component<CardHeaderProps>{


    public render(): JSX.Element {
        return <Row style={{ justifyContent: "space-between" }}>
            <h2 style={mainHeaderStyle}>{this.props.headerText}</h2>
            {
                this.props.showUserGuide && <Button
                    type="primary"
                    style={{ maxWidth: "45%" }}
                    onClick={this.navigateToUserguidePdf}
                    title="User guide"
                >
                    User guide <Icon type="double-right" />
                </Button>
            }
        </Row>
    }

    private navigateToUserguidePdf = () => {

        const location = encodeURI(window.location.origin + `/Global Model Workstation Online user guide.pdf${this.props.userGuidePage}`);

        window.open(location);
    }
}