import React from "react";

import { Button, Icon } from "antd";
import { Row, Col } from "../shared/layout";
import { Application } from "../Gmw-like-homepage/application";
import { SeparatorColor } from "../Gmw-like-homepage/styles";

type AppCardItem = {
    url: string;
    appDescription: string | JSX.Element;
    appDetails?: string | JSX.Element;
    linkText: string;
    onLinkClick?: (clickEvent: React.MouseEvent) => void;
}

type AppPropsBase = {
    showDetails: boolean;
    title: Application | string;
    image?: React.ReactElement;
    additionalContainerStyles?: React.CSSProperties;
    onTitleClick?: (clickEvent: React.MouseEvent) => void;
}

type AppPropsSingleItem = AppPropsBase & AppCardItem;
type AppPropsMultiItem =  AppPropsBase & { items: AppCardItem[] };
export type AppProps = AppPropsSingleItem | AppPropsMultiItem;

export class AppCard extends React.Component<AppProps> {

    public render() {
        const items: AppCardItem[] = isMultiItemProps(this.props) ? this.props.items : [this.props];

        const { title, additionalContainerStyles, image, showDetails } = this.props;
        const onTitleClick = this.props.onTitleClick || items[0]?.onLinkClick;

        return <Row style={{ ...additionalContainerStyles, textAlign: "left", padding: "2vh 0", borderTop: `1px solid ${SeparatorColor}` }}>
            <Col className={`app-card-desc ${!image ? "full-width" : ""}`}>
                <Row style={{ justifyItems: "center", lineHeight: "calc(12px + 1vmin)", fontSize: "calc(12px + 1vmin)", fontWeight: "bold", cursor: !!onTitleClick ?  "pointer" : undefined }} onClick={onTitleClick}>
                    {title}
                </Row>

                {items.map(item => {
                    return <>
                        {this.renderDescription(item)}
                        {showDetails && item.appDetails && <div className="app-card-details" style={{ fontSize: "calc(6px + 1vmin)", margin: "2% 0%", fontWeight: "lighter" }} >
                            {item.appDetails}
                        </div>}
                        <div>
                            {this.renderLink(item)}
                        </div>
                    </>
                })}
               
                
            </Col>
            {
                image &&
                <Col className="app-card-img" style={{ margin: "0 2% 2%" }}>
                {
                            showDetails && image
                }
                </Col>
            }
        </Row>;
    }

    private renderDescription(props: AppCardItem): JSX.Element | null {

        return props.appDescription
            ? this.props.showDetails
                ? <Col style={{ fontSize: "calc(6px + 1vmin)", margin: "2% 0%", fontWeight: "lighter" }}>
                    {props.appDescription}
                </Col>
                : <div style={{ fontSize: "calc(6px + 1vmin)", margin: "1% 0%", fontWeight: "lighter" }}>
                </div>
            : null
    }

    private renderLink(props: AppCardItem): JSX.Element {
        return this.props.showDetails
            ? <Button
                style={{ margin: 0, padding: 0 }}
                type="link"
                href={props.url}
                title={props.linkText}
                target={props.linkText}
                onClick={props.onLinkClick}
            >
                <div style={{ fontSize: "calc(8px + 1vmin)", fontStyle: "bold" }}> {props.linkText} <Icon type="double-right" /></div>
            </Button>
            : <React.Fragment />;
    }
}

function isMultiItemProps(props: AppProps): props is AppPropsMultiItem {
    return !!(props as AppPropsMultiItem).items;
}