
import { logInfo } from "./sentry-logger";
import { Forecast } from "../model/forecast";
import { ApplicationLink } from "../components//Gmw-like-homepage/quick-navigation-panel";


export interface AppNav {
    app: ApplicationLink;
    forecast: Forecast;
}

export class HistoryService {

    private readonly HISTORY_KEY = "history";
    private readonly history: AppNav[];
    private readonly maxNumberOfEntries: number;

    constructor(maxNumberOfEntries: number) {

        const localHistory = localStorage.getItem(this.HISTORY_KEY)
        if (localHistory) {
            this.history = JSON.parse(localHistory);
        } else {
            this.history = [];
        }

        this.maxNumberOfEntries = maxNumberOfEntries;
    }

    public getHistory(): AppNav[] {

        return this.history;

    }

    public addNav(app: ApplicationLink, fcst: Forecast) {

        if (this.history.length === this.maxNumberOfEntries) {

            const quickNavApp = this.history.pop();

            if (quickNavApp) {
                logInfo(`Removed ${quickNavApp.app.name}(${quickNavApp.app.url}) - ${quickNavApp.forecast.id} from list of recent activites.`);
            }

        }

        this.insertInLocalStorage(app, fcst);

        logInfo(`Added ${app.name}(${app.name}) - ${fcst.id} to the list of recent activities.`)
    }

    private insertInLocalStorage(app: ApplicationLink, fcst: Forecast) {
        this.history.unshift({ app, forecast: fcst });
        localStorage.setItem(this.HISTORY_KEY, JSON.stringify(this.history));
    }

    public clearHistory() {
        localStorage.setItem(this.HISTORY_KEY, JSON.stringify([]));
    }

}