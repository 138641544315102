import { Result, Results } from "../model/result";

export type FetchError = { 
        type: 'http'; 
        statusCode: number; 
        body: string; 
    }
    | { 
        type: 'other'; 
        error: Error | string;
    }
    ;

/** Sends a request and maps all errors (both HTTP and non-HTTP) as failed results instead of throwing them.*/
export async function fetchSafe<T = void>(request: Request, convertBody?: (body: string) => T): Promise<Result<T, FetchError>>  {
    try {
        const response = await fetch(request);
        if (response.ok) {
            if (convertBody) {
                return Results.Pass<T, FetchError>(convertBody(await response.text()));
            } else {
                return Results.Pass<T, FetchError>(0 as unknown as T);
            }
        } else {
            return Results.Fail({
                type: 'http',
                statusCode: response.status,
                body: await response.text()
            });
        }
    }
    catch (error) {
        return Results.Fail({ 
            type: 'other', 
            error: error instanceof Error 
                ? error 
                : `${error}`
        });
    }
}