
import React from "react";
import { Icon } from "antd";

import { Forecast } from "../../model/forecast";


interface ForecastDomainIconProps {
    forecast: Forecast | undefined;
}

export const ForecastDomainIcon: (props: ForecastDomainIconProps) => JSX.Element | null =
    ({ forecast }) => {

        if (forecast && forecast.economicDomain.code !== "Unknown") {
            return <img
                style={{ height: "2vh" }}
                title={forecast.economicDomain.name}
                src={forecast.economicDomain.iconUrl}
                alt={`${forecast.economicDomain.name} icon`}
            />
        }

        if (forecast?.economicDomain.code === "Unknown") {

            return <Icon title={forecast.economicDomain.name} type="question-circle" />;
        }

        return null;
    }