import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { GMWLikeApp } from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import { Auth0LoginService } from "./services/auth-service";
import { HistoryService } from "./services/history-service";
import { logError } from "./services/sentry-logger";
import * as Sentry from '@sentry/browser';

import { AppsConfig } from './model/apps-config';
import { Session } from './model/session';
import { Result, Results } from './model/result';
import { ErrorPage } from './components/shared/error-page';
import { PasswordExpiredPage } from './components/shared/password-expired-page';

Sentry.init({ dsn: "https://70b2212571894b0d98c70154185723c0@o25095.ingest.sentry.io/5225494" });

const paramNames = {
    passwordResetPage: 'showPasswordResetPage',
    returnUrl: 'returnUrl'
} as const;

async function main() {
    const appConfigResponse = await fetch("./apps.config.json");
    const appConfig = await appConfigResponse.json() as AppsConfig;

    const queryString = window.location.search;

    const stringParams = new URLSearchParams(queryString);
    const showPasswordResetPage = stringParams.has(paramNames.passwordResetPage) && stringParams.get(paramNames.passwordResetPage) === 'true';
    const returnUrl = stringParams.has(paramNames.returnUrl) && stringParams.get(paramNames.returnUrl);

    if (!showPasswordResetPage) {

        const loginResult = await login(appConfig);
        const history = new HistoryService(5);

        if (loginResult.success) {
            const session = loginResult.value;
            Sentry.setUser({ id: session.name, company: session.company });
            ReactDOM.render(<GMWLikeApp session={session} applications={appConfig} history={history} />, document.getElementById('root'));
        } else if (loginResult.error.type === "PasswordExpired") {
            ReactDOM.render(
                <PasswordExpiredPage auth0={appConfig.auth0} />,
                document.getElementById('root')
            );
        } else {
            ReactDOM.render(
                <ErrorPage title="An error occurred during login.">
                    <p>{loginResult.error.message}</p>
                    <p>Click <a href="/">here</a> to try again.</p>
                </ErrorPage>,
                document.getElementById('root')
            );
        }
    } else {
        ReactDOM.render(
            <PasswordExpiredPage auth0={appConfig.auth0} returnUrl={returnUrl || ""} />,
            document.getElementById('root')
        )
    }
}

type LoginError = {
    type: "PasswordExpired";
} | {
    type: "Other";
    message: string;
}

async function login(appConfig: AppsConfig): Promise<Result<Session, LoginError>> {

    const auth = new Auth0LoginService({
        clientId: appConfig.auth0.clientId,
        domain: appConfig.auth0.domain,
        audience: appConfig.auth0.apiIdentifier,
        scopes: ["Roles"]
    });

    try {
        const session = await auth.getAuthSession();
        if (session.status === "Success") {
            return Results.Pass({
                name: session.name,
                company: session.company,
                token: session.accessToken,
                logout: url => auth.logout(url)
            });
        } else if (session.failureType === "PasswordExpired") {
            return Results.Fail({ type: "PasswordExpired" });
        } else {
            return Results.Fail({ type: "Other", message: session.error.message });
        }
    } catch (error) {
        return Results.Fail({ type: "Other", message: `${error}` });
    }
}

main().catch(e => logError(e, "Critical error when initialising application"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
